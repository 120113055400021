<template>
  <div class="flyRewardsDashboard" :class="'flyRewardsDashboard--' + dashboardType">
    <div v-if="dashboardType === 'flyer' || dashboardType === 'provider'" class="flyRewardsDashboard__top">
      <div class="flyRewardsDashboard__header">
        <div class="flyRewardsDashboard__header-icon">
          <SvgIcon name="giftbox"/>
        </div>
        <div class="flyRewardsDashboard__header-text">FLY<b>Rewards</b> are granted to Flyers and Aircraft Providers
          with each booking, and <b>may be used as credit toward the purchase of future flights.</b></div>
      </div>
      <div class="flyRewardsDashboard__content">
        <div class="flyRewardsDashboard__available">
          <div class="flyRewardsDashboard__available-title">Available fly<b>rewards</b></div>
          <div class="flyRewardsDashboard__available-circle">
            <div class="flyRewardsDashboard__available-circle-line flyRewardsDashboard__available-circle-line--1">
              <svg width="223" height="223" viewBox="0 0 223 223" fill="none">
                <circle opacity="0.4" cx="111.5" cy="111.5" r="111" stroke="url(#available-line-01)"
                        stroke-dasharray="3 3"/>
                <path
                  d="M155.837 212.977C156.064 213.481 156.703 213.724 157.208 213.497L160.674 211.936L166.095 215.56L167.355 214.992L163.51 210.66L166.976 209.099L168.489 209.934L169.434 209.509L167.811 207.586L167.449 205.097L166.503 205.522L166.125 207.208L162.659 208.769L161.965 203.018L160.704 203.585L159.823 210.046L156.356 211.606C155.852 211.833 155.61 212.473 155.837 212.977Z"
                  fill="url(#available-line-11)"/>
                <defs>
                  <linearGradient id="available-line-01" x1="111.5" y1="0" x2="111.5" y2="223"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                  <linearGradient id="available-line-11" x1="164.834" y1="216.127" x2="159.444" y2="204.152"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="flyRewardsDashboard__available-circle-line flyRewardsDashboard__available-circle-line--2">
              <svg width="187" height="187" viewBox="0 0 187 187" fill="none">
                <circle cx="93.5" cy="93.5" r="93" stroke="url(#available-line-02)" stroke-dasharray="3 3"/>
                <path
                  d="M54.9477 8.89256C54.6194 8.2988 53.8127 8.06647 53.2189 8.39472L49.1368 10.6514L42.1432 6.76535L40.6588 7.58598L45.7968 12.4978L41.7147 14.7546L39.7808 13.8856L38.6674 14.5011L40.8457 16.6885L41.5396 19.6965L42.6529 19.0811L42.9456 16.9812L47.0278 14.7245L48.4547 21.6879L49.9392 20.8673L50.3677 12.8781L54.4498 10.6213C55.0436 10.2931 55.2759 9.48633 54.9477 8.89256Z"
                  fill="url(#available-line-12)"/>
                <defs>
                  <linearGradient id="available-line-02" x1="93.5" y1="0" x2="93.5" y2="187"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                  <linearGradient id="available-line-12" x1="43.6276" y1="5.94473" x2="51.4236" y2="20.0467"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="flyRewardsDashboard__available-circle-inner">
              <div class="flyRewardsDashboard__available-circle-inner-value">{{ flyerFlyrewardsPoin }}</div>
            </div>
          </div>
        </div>
        <div class="flyRewardsDashboard__info">
          <span><b>1</b>&nbsp;fly<b>reward</b></span><span>&nbsp;=&nbsp;</span><span><b>$1</b></span></div>
        <div class="flyRewardsDashboard__currency">
          <div class="flyRewardsDashboard__currency-title"><b>Currency ($)</b> Equivalent</div>
          <div class="flyRewardsDashboard__currency-circle">
            <div class="flyRewardsDashboard__currency-circle-line">
              <svg width="189" height="189" viewBox="0 0 189 189" fill="none">
                <circle cx="94.5" cy="94.5" r="94" stroke-dasharray="3 3"></circle>
              </svg>
            </div>
            <div class="flyRewardsDashboard__currency-circle-inner">${{ flyerFlyrewardsPoin }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dashboardType === 'flyer' || dashboardType === 'provider'" class="flyRewardsDashboard__bottom">
      <div class="flyRewardsDashboard__title">Historical flights</div>
      <div class="flyRewardsDashboard__history">
        <div class="flyRewardsDashboard__history-header">
          <div class="flyRewardsDashboard__history-header-inner">
            <div class="flyRewardsDashboard__history-cell">№</div>
            <div class="flyRewardsDashboard__history-cell">Confirm. no</div>
            <div class="flyRewardsDashboard__history-cell">Booking date</div>
            <div class="flyRewardsDashboard__history-cell">Origin</div>
            <div class="flyRewardsDashboard__history-cell">Destination</div>
            <div class="flyRewardsDashboard__history-cell">Price</div>
            <div class="flyRewardsDashboard__history-cell">FLY REWARDS</div>
          </div>
        </div>
        <div class="flyRewardsDashboard__history-body js-customScroll">
          <vue-scroll :ops="ops">
            <div
              class="flyRewardsDashboard__history-row"
              v-for="(item, index) of historicalFlyrewardsList"
              :key="index"
            >
              <div class="flyRewardsDashboard__history-cell">
                <div class="flyRewardsDashboard__history-index">{{ historicalFlyrewardsList.length - index }}</div>
              </div>
              <div class="flyRewardsDashboard__history-cell">{{item.confirmNo}}</div>
              <div class="flyRewardsDashboard__history-cell">{{moment(item.date).format('M/D/YYYY')}}</div>
              <div class="flyRewardsDashboard__history-cell flyRewardsDashboard__history-cell--from">
                <span>{{ item.origin }}</span>
                <span class="arrow">
                <SvgIcon name="right-arrow-2"/>
              </span>
              </div>
              <div class="flyRewardsDashboard__history-cell">{{ item.destination }}</div>
              <div class="flyRewardsDashboard__history-cell">
                <div class="flyRewardsDashboard__history-price"><!--$-->{{ item.price }}</div>
              </div>
              <div class="flyRewardsDashboard__history-cell">
                <div class="flyRewardsDashboard__table-status">
                  <span class="flyRewardsDashboard__table-status-value">{{item.flyRewards}}</span>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div v-else-if="dashboardType === 'admin'" class="flyRewardsDashboard__table">
      <div class="flyRewardsDashboard__table__nav">
        <div class="flyRewardsDashboard__table__navLeft">
          <div class="flyRewardsDashboard__table__nav-item js-tabRequestsNavItem active" @click="(e) => {showActiveList('ready for release'); tabActivate(e)}"><span
            class="text">READY FOR RELEASE</span></div>
          <div class="flyRewardsDashboard__table__nav-item js-tabRequestsNavItem" @click="(e) => {showActiveList('released'); tabActivate(e)}"><span class="text">RELEASED</span></div>
        </div>
      </div>
      <div class="flyRewardsDashboard__table">
        <!--        <div class="flyRewardsDashboard__table-header">-->
        <!--          <div class="flyRewardsDashboard__table-header-left">-->
        <!--            <div class="flyRewardsDashboard__table-cell">№</div>-->
        <!--            <div class="flyRewardsDashboard__table-cell">Name</div>-->
        <!--            <div class="flyRewardsDashboard__table-cell">Fly<b>Rewards</b></div>-->
        <!--          </div>-->
        <!--          <div class="flyRewardsDashboard__table-header-right">-->
        <!--            <div class="flyRewardsDashboard__table-cell">Additional flyrewards</div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="flyRewardsDashboard__table-header">
          <div class="flyRewardsDashboard__table-cell">№</div>
          <div class="flyRewardsDashboard__table-cell">CONFIRM. NO</div>
          <div class="flyRewardsDashboard__table-cell">Name</div>
          <div class="flyRewardsDashboard__table-cell flyRewardsDashboard__table-cell--date">BOOKING DATE</div>
          <div class="flyRewardsDashboard__table-cell">ORIGIN</div>
          <div class="flyRewardsDashboard__table-cell">DESTINATION</div>
          <div class="flyRewardsDashboard__table-cell">PRICE</div>
          <div class="flyRewardsDashboard__table-cell">FLY REWARDS</div>
        </div>
<!--        <div class="flyRewardsDashboard__table-body js-customScroll">-->
<!--          <vue-scroll :ops="ops">-->
<!--            <div-->
<!--              v-for="(item, index) of flyrewardsList"-->
<!--              :key="item.id"-->
<!--              class="flyRewardsDashboard__table-row"-->
<!--            >-->
<!--              <div class="flyRewardsDashboard__table-row-left js-acc-item">-->
<!--                <div class="flyRewardsDashboard__table-row-header js-acc-title" @click="displayRewardHistory">-->
<!--                  <div class="flyRewardsDashboard__table-cell">-->
<!--                    <div class="flyRewardsDashboard__table-index">{{ index + 1 }}</div>-->
<!--                  </div>-->
<!--                  <div class="flyRewardsDashboard__table-cell">-->
<!--                    <router-link class="flyRewardsDashboard__table-name" to="">{{ item.name }}</router-link>-->
<!--                  </div>-->
<!--                  <div class="flyRewardsDashboard__table-cell">-->
<!--                    <div class="flyRewardsDashboard__table-count">-->
<!--                      <span>{{ item.flyRewards }}</span>-->
<!--                      <span class="flyRewardsDashboard__table-count-caption">&nbsp;FlyRewards</span>-->
<!--                    </div>-->
<!--                    <div class="flyRewardsDashboard__table-arrow">-->
<!--                      <SvgIcon name="down-arrow"/>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="flyRewardsDashboard__table-row-content js-acc-content-history">-->
<!--                  <div class="flyRewardsDashboard__table-row-content-inner">-->
<!--                    <div class="flyRewardsDashboard__table-history">-->
<!--                      <div-->
<!--                        v-for="(history, id) of item.rewardsHistory"-->
<!--                        :key="id"-->
<!--                        class="flyRewardsDashboard__table-history-item js-flyrewards-cancel"-->
<!--                        :class="{'cancel' : history.cancelled === false}"-->
<!--                        data-href="#questionPopup"-->
<!--                      >-->
<!--                        <div-->
<!--                          v-if="history.cancelled === false"-->
<!--                          class="flyRewardsDashboard__table-history-item-wrapper"-->
<!--                          @click="showModal(item.id, history.id)"-->
<!--                        >-->
<!--                          <div class="flyRewardsDashboard__table-history-tooltip">-->
<!--                            Mary, you can cancel the charge <b>FLYRewards</b> for a minute-->
<!--                          </div>-->
<!--                          <div class="flyRewardsDashboard__table-history-count">Added <span>{{ history.value }}</span>-->
<!--                            Fly<b>Rewards</b>-->
<!--                          </div>-->
<!--                          <div class="flyRewardsDashboard__table-history-date">{{ history.date }}</div>-->
<!--                          <div class="flyRewardsDashboard__table-history-icon">-->
<!--                            <SvgIcon name="back-arrow"/>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div v-else class="flyRewardsDashboard__table-history-item-wrapper">-->
<!--                          <div class="flyRewardsDashboard__table-history-count">Added <span>{{ history.value }}</span>-->
<!--                            Fly<b>Rewards</b>-->
<!--                          </div>-->
<!--                          <div class="flyRewardsDashboard__table-history-date">{{ history.date }}</div>-->
<!--                          <div class="flyRewardsDashboard__table-history-icon">-->
<!--                            <SvgIcon name="checked"/>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="flyRewardsDashboard__table-row-info">Mary, you can cancel the charge <b>FLYRewards</b>-->
<!--                      for a minute-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flyRewardsDashboard__table-row-right">-->
<!--                <div class="flyRewardsDashboard__table-row-title">Additional<br>flyrewards:</div>-->
<!--                <form class="form form&#45;&#45;addFlyrewards js-addFlyrewardsForm">-->
<!--                  <label class="form__label form__label&#45;&#45;number">-->
<!--                    <div class="form__field-wrapper">-->
<!--                      <input-->
<!--                        class="form__field js-numberOnly"-->
<!--                        type="number"-->
<!--                        name="flyrewards"-->
<!--                        placeholder="50"-->
<!--                        autocomplete="off"-->
<!--                        @focus="rewardFocus"-->
<!--                        @blur="rewardBlur"-->
<!--                        required="required"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </label>-->
<!--                  <div class="form__label form__label&#45;&#45;button">-->
<!--                    <button class="form__button">-->
<!--                      <span class="text js-addFlyrewards-text" data-text="Add additional flyrewards">Add</span>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->
<!--            </div>-->
<!--          </vue-scroll>-->
<!--        </div>-->
        <div class="flyRewardsDashboard__table-body js-customScroll">
          <vue-scroll :ops="ops">
            <div
              v-for="(item, index) of flyrewardsList"
              :key="item.id"
              class="flyRewardsDashboard__table-row"
            >
              <div
                class="flyRewardsDashboard__table-row-inner js-acc-hover-item"
                @mouseenter="controlShow"
                @mouseleave="controlHide"
                v-if="activeTabs === 0"
              >
                <div class="flyRewardsDashboard__table-row-header">
                  <div class="flyRewardsDashboard__table-cell">
                    <div class="flyRewardsDashboard__table-index">{{flyrewardsList.length - index}}</div>
                  </div>
                  <div class="flyRewardsDashboard__table-cell">{{item.confirmNo}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.name}}</div>
                  <div class="flyRewardsDashboard__table-cell flyRewardsDashboard__table-cell--date">{{moment(item.date).format('M/D/YYYY')}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.origin}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.destination}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.price}}</div>
                  <div class="flyRewardsDashboard__table-cell">
                    <div class="flyRewardsDashboard__table-status">
                      <input class="flyRewardsDashboard__table-status-value" style="width: 100%; background: transparent; border: none;" type="number" v-model="item.flyRewards">
                    </div>
                    <div class="flyRewardsDashboard__table-arrow">
                      <span class="icon" style="margin-right: 15px" @click="() => decrementFlyRewards(item)"><SvgIcon name="down-arrow" /></span>
                      <span class="icon" @click="() =>incrementFlyRewards(item)"><SvgIcon name="up-arrow" /></span>
                    </div>
                  </div>
                </div>
                <div class="flyRewardsDashboard__table-row-control js-acc-hover-content">
                  <div class="flyRewardsDashboard__table-row-control-inner">
                    <p class="flyRewardsDashboard__table-link" @click="() => showQuestionReleasePopup(item.bookingId, item.flyRewards)">
                      <span class="icon"><SvgIcon name="checkmark"/></span>
                      <span class="text">RELEASE FLYREWARDS</span>
                    </p>
                    <p class="flyRewardsDashboard__table-link" @click="() => showQuestionRemovePopup(item.bookingId, 0)">
                      <span class="icon"><SvgIcon name="close-bold"/></span>
                      <span class="text">REMOVE FLYREWARDS</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="flyRewardsDashboard__table-row-inner js-acc-hover-item"
                v-else
              >
                <div class="flyRewardsDashboard__table-row-header">
                  <div class="flyRewardsDashboard__table-cell">
                    <div class="flyRewardsDashboard__table-index">{{flyrewardsList.length - index}}</div>
                  </div>
                  <div class="flyRewardsDashboard__table-cell">{{item.confirmNo}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.name}}</div>
                  <div class="flyRewardsDashboard__table-cell flyRewardsDashboard__table-cell--date">{{moment(item.date).format('M/D/YYYY')}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.origin}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.destination}}</div>
                  <div class="flyRewardsDashboard__table-cell">{{item.price}}</div>
                  <div class="flyRewardsDashboard__table-cell">
                    <div class="flyRewardsDashboard__table-status">
                      <span class="flyRewardsDashboard__table-status-value">{{item.flyRewards}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <QuestionPopup
      :name="'questionPopupRewards'"
      :title="'The flyRewards will be cancel'"
      :text="'Are you sure?'"
      @remove="remove"
    />
    <QuestionPopup
      :name="'questionPopupReleaseRewards'"
      :title="'The flyRewards will be released'"
      :text="'Are you sure?'"
      @remove="released"
    />
    <InfoPopup/>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import QuestionPopup from '@/components/common/modals/QuestionPopup'
import moment from 'moment'
import InfoPopup from '@/components/common/modals/InfoPopup';
import './FlyRewards.styl'
import {Tabs} from '@/js/plugins/tabs';

export default {
  props: ['dashboardType'],
  name: 'FlyRewards',
  data: () => ({
    tabs: new Tabs('.js-tabRequestsNavItem', '.js-tabRequestsContentItem'),
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    activeTabs: 0,
    currentFlyRewardId: '',
    currentFlyRewardPoint: '',
  }),
  async created () {
    if (this.$props.dashboardType === 'admin') {
      this.$store.dispatch('getFlyRewards', 0)
    } else {
      this.$store.dispatch('getFlyerFlyRewards')
      this.$store.dispatch('getHistoricalFlyRewards')
    }
  },
  computed: {
    flyrewardsList () {
      return this.$store.state.bookingFromSearch.flyRewardsList
    },
    flyerFlyrewardsPoin () {
      return this.$store.state.bookingFromSearch.flyerFlyRewardsPoint
    },
    historicalFlyrewardsList () {
      return this.$store.state.bookingFromSearch.historicalRewardsList
    },
  },
  methods: {
    moment,
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
    },
    showActiveList (type) {
      if (type === 'released') {
        this.$store.dispatch('getFlyRewards', 1)
        this.activeTabs = 1
      } else {
        this.$store.dispatch('getFlyRewards', 0)
        this.activeTabs = 0
      }
    },
    incrementFlyRewards (item) {
      item.flyRewards = Math.floor(item.flyRewards + 1)
    },
    decrementFlyRewards (item) {
      item.flyRewards = Math.floor(item.flyRewards - 1)
    },
    async updateFlyRewardsStatus (id, point) {
      const data = {
        id: id,
        flyRewards: point,
      }
      await this.$store.dispatch('updateFlyRewards', data)
        .then((res) => {
        res && this.$modal.show('InfoPopup', {
            title: 'Processed',
            text: 'Processed successfully.',
          })
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        setTimeout(() => { this.$store.dispatch('getFlyRewards', 0) }, 1000)
      })
    },
    showQuestionRemovePopup (id, point) {
      this.currentFlyRewardId = id
      this.currentFlyRewardPoint = point
      this.$modal.show('questionPopupRewards')
    },
    showQuestionReleasePopup (id, point) {
      this.currentFlyRewardId = id
      this.currentFlyRewardPoint = point
      this.$modal.show('questionPopupReleaseRewards')
    },
    displayRewardHistory (event) {
      const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content-history')
      event.currentTarget.closest('.js-acc-item').classList.toggle('mutation')
      document.querySelectorAll('.js-acc-content-history').forEach((content) => {
        if (item !== content) {
          content.querySelector('.flyRewardsDashboard__table-row-content-inner').style.opacity = 0
          content.closest('.js-acc-item').classList.remove('mutation')
          this.$root.animations.slideUp(content)
        }
      })

      this.$root.animations.slideToggle(item)
      const innerContent = item.querySelector('.flyRewardsDashboard__table-row-content-inner')

      if (window.getComputedStyle(innerContent).opacity === '1') {
        innerContent.style.opacity = 0
      } else {
        item.addEventListener('transitionend', () => {
          if (window.getComputedStyle(innerContent).opacity === '0') {
            innerContent.style.opacity = 1
          }
        }, {once: true})
      }
    },
    controlShow (event) {
      const item = event.currentTarget.closest('.flyRewardsDashboard__table-row').querySelector('.js-acc-hover-content')
      this.$root.animations.slideDown(item)
      item.classList.add('show')
    },
    controlHide (event) {
      const item = event.currentTarget.closest('.flyRewardsDashboard__table-row').querySelector('.js-acc-hover-content')
      item.classList.remove('show')
      this.$root.animations.slideUp(item)
    },

    showModal (userId, historyId) {
      this.$modal.show('questionPopupRewards')
      this.$store.commit('insertCurrentReward', {
        userId: userId,
        historyId: historyId,
      })
    },
    remove () {
      this.updateFlyRewardsStatus(this.currentFlyRewardId, this.currentFlyRewardPoint)
    },
    released () {
      this.updateFlyRewardsStatus(this.currentFlyRewardId, this.currentFlyRewardPoint)
    },
    rewardFocus (event) {
      const html = document.querySelector('html')
      const form = event.target.closest('.js-addFlyrewardsForm')
      form.classList.add('focus')
      if (!html.classList.contains('d-mobile')) {
        form.querySelector('.js-addFlyrewards-text').innerHTML = 'Add additional flyrewards'
      }
    },
    rewardBlur (event) {
      const html = document.querySelector('html')
      const form = event.target.closest('.js-addFlyrewardsForm')
      form.classList.remove('focus')
      if (!html.classList.contains('d-mobile')) {
        form.querySelector('.js-addFlyrewards-text').innerHTML = 'Add'
      }
    },
  },
  components: {
    SvgIcon,
    QuestionPopup,
    InfoPopup,
  },
}
</script>
