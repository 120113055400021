<template>
  <modal :name="name" transition="pop-out" @opened="opened">
    <div class="thanksPopup modal">
      <div class="thanksPopup__decor"></div>
      <div class="thanksPopup__inner">
        <div class="thanksPopup__title">{{title}}</div>
        <div class="thanksPopup__text">{{text}}</div>
        <div class="thanksPopup__button-wr">
          <div
            class="thanksPopup__button thanksPopup__button--yes js-yes-btn"
            @click="deleteTravelerCloseModal"
          >
            <span class="text">Yes</span>
          </div>
          <div
            class="thanksPopup__button thanksPopup__button--no"
            @click="this.hide"
          >
            <span class="text">No</span>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="this.hide">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    name: 'QuestionPopup',
    props: ['title', 'text', 'name'],
    methods: {
      show () {
        this.$modal.show(this.name)
      },
      hide () {
        this.$modal.hide(this.name)
      },
      deleteTravelerCloseModal () {
        this.hide()
        this.$emit('remove')
      },
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const popup = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(popup)
        }
      },
    },
    created () {
      this.$parent.$on('showModal', this.show)
    },
    components: {
      SvgIcon,
    },
  }
</script>
